<template>
    <div class="row">
        <h1 class="text-start">Maid name</h1>
        <div class="col-md-3 black-right text-start screen-scroll" >
            <div class="card mb-2"  v-for="user in Users" :key="user.id">
                <div class="card-body">
                    <h4>{{ user.username }}</h4>
                    <div class="flex-display">
                        <h6>
                        {{ user.email }}
                    </h6>
                    <button class="btn btn-success" @click="display_message(current_contractor.id, user.id)">View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9" style="max-height: 87vh; overflow: hidden;">
            <div class="chat" >
                <div v-for="message in UserMessages" :key="message.id" class="msg" :class="{rcvd:message.message_type=='user' , sent:message.message_type=='system'}">
                    <p>{{  message.text }}</p>
                    <p>{{ message.timestamp }}</p>
                </div>
                <!-- <div v-for="message in UserMessages" :key="message.id" class="msg" :class="{rcvd:message.message_type=='user' , sent:message.message_type=='system'}">
                    <p>{{  message.text }}</p>
                    <p>{{ message.timestamp }}</p>
                </div>
                <div v-for="message in UserMessages" :key="message.id" class="msg" :class="{rcvd:message.message_type=='user' , sent:message.message_type=='system'}">
                    <p>{{  message.text }}</p>
                    <p>{{ message.timestamp }}</p>
                </div>
                <div v-for="message in UserMessages" :key="message.id" class="msg" :class="{rcvd:message.message_type=='user' , sent:message.message_type=='system'}">
                    <p>{{  message.text }}</p>
                    <p>{{ message.timestamp }}</p>
                </div>
                <div v-for="message in UserMessages" :key="message.id" class="msg" :class="{rcvd:message.message_type=='user' , sent:message.message_type=='system'}">
                    <p>{{  message.text }}</p>
                    <p>{{ message.timestamp }}</p>
                </div>
                <div v-for="message in UserMessages" :key="message.id" class="msg" :class="{rcvd:message.message_type=='user' , sent:message.message_type=='system'}">
                    <p>{{  message.text }}</p>
                    <p>{{ message.timestamp }}</p>
                </div>
                <div v-for="message in UserMessages" :key="message.id" class="msg" :class="{rcvd:message.message_type=='user' , sent:message.message_type=='system'}">
                    <p>{{  message.text }}</p>
                    <p>{{ message.timestamp }}</p>
                </div> -->
            </div>
            <div>
                <form class="row">
                    <div class="col-md-9 mb-2">
                        <textarea name="" id="" rows="3"  class="form-control" placeholder="Message" v-model="message.text"></textarea>
                    </div>
                    <button type="submit" class="col-md-3 btn btn-primary" @click="send_message">Send</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default{
    data() {
        return {
            polling: null,
            current_contractor: {
                fullname:"",
                id:""
            },
            message : {
                user_id: "",
                text: "",
                contractor_id: ""
            }
        }
    },
    computed: {
        ...mapGetters({Users: "StateMaidUsers", UserMessages: "StateMaidUserMessages"})
    },
    async mounted(){
        var contractor_id = this.$route.query.id;
        this.current_contractor.id = contractor_id
        await this.GetMaidUsers(contractor_id);


    },
    beforeUnmount(){
        console.log("Unmounting....")
        if (this.polling != null){
            clearInterval(this.polling);
            console.log("Cancelling polling");
        }
        else {
            console.log("No polling  yet");
        }
    },
    methods: {
        ...mapActions(["GetMaidUsers", "GetMaidUserMessages", "SendMaidUserMessage"]),

        display_message(contractor_id, user_id){
            this.message.user_id = user_id;
            this.message.contractor_id = contractor_id
            this.GetMaidUserMessages({contractor_id, user_id})

            if (this.polling != null){
                clearInterval(this.polling);
                console.log("Cancelling polling");
            }
            else {
                console.log("No polling  yet");
            }
            this.polling = setInterval(() => {
                this.GetMaidUserMessages({contractor_id, user_id})
            }, 5000)
        },

        async send_message(){
            if (this.message.text == "") return
            await this.SendMaidUserMessage(this.message)
            this.message.text = ""

        }

    }
}
</script>
<style scoped>
    .black-right {
        border-right: 2px solid black;
    }

    .screen-scroll {
        height: 87vh;
        max-height: 90vh;
        overflow-y: auto;
    }

    .flex-display {
        display: flex;
        justify-content: space-between;
    }

    /* Message */
    .chat {
    --rad: 20px;
    --rad-sm: 3px;
    font: 16px/1.5 sans-serif;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    /* margin: auto; */
    height: 77vh;
    margin-bottom: 2px;
     overflow-y: auto;
    }

    .msg {
    position: relative;
    max-width: 75%;
    padding: 7px 15px;
    margin-bottom: 2px;
    }

    .msg.sent {
    border-radius: var(--rad) var(--rad-sm) var(--rad-sm) var(--rad);
    background: #42a5f5;
    color: #fff;
    /* moves it to the right */
    margin-left: auto;
    }

    .msg.rcvd {
    border-radius: var(--rad-sm) var(--rad) var(--rad) var(--rad-sm);
    background: #08d119e7;
    color: #fff;
    /* moves it to the left */
    margin-right: auto;
    }

    /* Improve radius for messages group */

    .msg.sent:first-child,
    .msg.rcvd+.msg.sent {
    border-top-right-radius: var(--rad);
    }

    .msg.rcvd:first-child,
    .msg.sent+.msg.rcvd {
    border-top-left-radius: var(--rad);
    }


    /* time */

    .msg::before {
    content: attr(data-time);
    font-size: 0.8rem;
    position: absolute;
    bottom: 100%;
    color: #888;
    white-space: nowrap;
    /* Hidden by default */
    display: none;
    }

    .msg.sent::before {
    right: 15px;
    }

    .msg.rcvd::before {
    left: 15px;
    }


    /* Show time only for first message in group */

    .msg:first-child::before,
    .msg.sent+.msg.rcvd::before,
    .msg.rcvd+.msg.sent::before {
    /* Show only for first message in group */
    display: block;
    }

</style>